import React from 'react';
const QueryLoader = ({ topPadding, opacity }) => (
  <div
    className="flex flex-align-center flex-justify-center"
    style={{ paddingTop: topPadding, opacity: opacity }}
  >
    <div className="beat-dot" />
    <div className="beat-dot middle" />
    <div className="beat-dot" />
  </div>
);

QueryLoader.defaultProps = {
  topPadding: 300,
  opacity: '1'
};
export default QueryLoader;
