import { useReducer } from 'react';

const initialState = {
  pushing: false,
  succeeded: false,
  failed: false
};

const reducer = (state, action) => {
  switch (action) {
    case 'pushing':
      return { pushing: true, succeeded: false, failed: false };
    case 'succeeded':
      return { pushing: false, succeeded: true, failed: false };
    case 'failed':
      return { pushing: false, failed: true, succeeded: false };
    default:
      return { pushing: false, succeeded: false, failed: false };
  }
};

const useLoadingButtonState = () => useReducer(reducer, initialState);

export default useLoadingButtonState;
