/**
 * Check whether an object has n number of properties
 * E.g - hasNestedProperty(obj, 'level1', 'level2', 'myKey')
 *        - hasNestedProperty(history.location, 'state', 'error')
 * @param {*} obj
 * @param {*} level
 * @param {*} rest
 * @returns
 */
export function hasNestedProperty(obj, level, ...rest) {
  if (obj === undefined) return false;
  if (rest.length == 0 && obj.hasOwnProperty(level)) return true;
  return hasNestedProperty(obj[level], ...rest);
}

/**
 * Get the value at `path` of `object`. If the resolved
 * value of `undefined`, the `defaultValue` is returned instead.
 *
 * @param {Object} object
 * @param {Array} path
 * @param {String} [defaultValue]
 */
export const get = (object, path, defaultValue) =>
  path.reduce((obj, key) => (obj && obj[key] ? obj[key] : defaultValue), object);

export const apiUri =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:9000'
    : 'https://timesheet.psi.edu:5000';

export const convertNumericStatusToText = status => {
  if (status === 0) {
    return 'Created';
  } else if (status === 1) {
    return 'Submitted';
  } else if (status === 2) {
    return 'Awaiting Approval';
  } else {
    return 'Approved';
  }
};

/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/race
 *
 * Whichever promise resolves first is returned, If fetch takes longer than 6000ms then
 * a rejected promise containing an Error is returned, which we can handle with a
 * .catch block
 * @param {string} url
 * @param {object} options
 * @param {number} [timeout=6000]
 * @returns
 */
export const fetchWithTimeout = (url, options, timeout = 6000) => {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Request timed out')), timeout)
    )
  ]);
};
