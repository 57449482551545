import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ id, initialClasses, disabled, onClick, children }) => (
  <button
    className={`${initialClasses} ${disabled ? 'btn--disabled' : ''}`}
    onClick={onClick}
    disabled={disabled}
    id={id}
  >
    {children}
  </button>
);

Button.defaultProps = {
  disabled: false,
  initialClasses: '',
  id: ''
};

Button.propTypes = {
  id: PropTypes.string,
  initialClasses: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.instanceOf(React.Children)
};

export default Button;
