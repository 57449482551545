import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from './Button';

const LoadingButton = ({
  onClick,
  onLoaderClick,
  isLoading,
  succeeded,
  failed,
  succeededMessage,
  failedMessage,
  buttonClasses,
  buttonDisabled,
  children
}) => {
  const [resetButton, setResetButton] = useState(false);

  const loaderClasses = classNames(
    'circle-loader',
    { 'load-complete checkmark': succeeded },
    { 'load-complete failmark': failed }
  );

  const containerClasses = classNames(
    'button-loader-container flex flex-direction-column flex-align-center flex-justify-center margin-2-t',
    { ' succeeded': succeeded },
    { failed: failed }
  );
  const textClass = classNames(
    'loader-text',
    { ' succeeded': succeeded },
    { failed: failed }
  );
  // return transitions.map(({ item, key, props }) =>
  //   item
  //     ? (
  //       <animated.div style={props} className={loaderClasses}>
  //         <div className='draw' />
  //       </animated.div>
  //     )
  //     : (
  //         <animated.button style={props} onClick={onClick} className="btn btn--md">
  //           {buttonText}
  //         </animated.button>
  //       )
  // );
  if (isLoading || succeeded || failed)
    return (
      <div className={containerClasses}>
        <div
          className={loaderClasses}
          onClick={() => (succeeded || failed ? onLoaderClick() : null)}
        >
          <div className="draw" />
        </div>
        {(succeeded || failed) && (
          <div
            className={textClass}
            onClick={() => (succeeded || failed ? onLoaderClick() : null)}
          >
            {succeeded ? succeededMessage : failedMessage}
          </div>
        )}
      </div>
    );
  return (
    <Button initialClasses={buttonClasses} onClick={onClick} disabled={buttonDisabled}>
      {children}
    </Button>
  );
};

export default LoadingButton;
