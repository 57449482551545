import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PageLoader from 'components/QueryLoader';
import ErrorBoundry from 'components/ErrorBoundry';
import { IntlProvider } from 'react-intl';
import language from 'assets/language/en_US.json';
import { ApolloProvider } from '@apollo/react-hoc';
import { client } from './ApolloClient';
import ScrollToTop from 'components/RouterScrollToTop';
import SessionTimeout from 'components/Auth/SessionTimeoutAlertCountdown';
import PrivateRoute from 'components/PrivateRoute';
import { AppProvider } from './app-context';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import './assets/styles/App.scss';

const MyCoversheet = lazy(() => import('views/Timesheet/Coversheet/MyCoversheet'));
const HomePage = lazy(() => import('views/HomePage/HomePage'));
const LoginPage = lazy(() => import('views/HomePage/LoginPage'));
const AboutPage = lazy(() => import('views/Timesheet/About/About'));
const HelpPage = lazy(() => import('views/Timesheet/Help/Help'));
const ContactPage = lazy(() => import('views/Timesheet/Contact/Contact'));
const Navbar = lazy(() => import('components/PSINavbar'));
const ProjList = lazy(() => import('views/Timesheet/Profile/Projects'));
const TimesList = lazy(() => import('views/Timesheet/Timesheets/ManageTimesheets'));
const TSMenu = lazy(() => import('views/Timesheet/timesheetMenu'));
const ExecReview = lazy(() => import('views/Timesheet/ExecutiveReview/ExecutiveReview'));
const Supervisor = lazy(() => import('views/Timesheet/Supervisor/SupervisorContainer'));
const Metrics = lazy(() => import('views/Timesheet/MetricsDashboard/MetricsDashboard'));
const NotFound = lazy(() => import('components/404NotFound'));
const SendEmails = lazy(() => import('views/EmailSending/SendEmails'));
const FMDash = lazy(() => import('views/Timesheet/FinancialManagerDashboard/Dashboard'));

const Root = () => {
  return (
    <AppProvider>
      <IntlProvider locale="en" messages={language}>
        <BrowserRouter>
          <ScrollToTop>
            <ApolloProvider client={client}>
              <ErrorBoundry>
                <Suspense fallback={<PageLoader opacity="0.5" />}>
                  <SessionTimeout />
                  <Route path="/" key="navbar" component={Navbar} />
                  <Suspense fallback={<PageLoader opacity="0.5" />}>
                    <Switch>
                      <PrivateRoute
                        path="/home"
                        exact
                        key="homePage"
                        component={HomePage}
                      />
                      <Route path="/login" key="loginPage" component={LoginPage} />
                      <PrivateRoute
                        path="/timesheet/about"
                        exact
                        key="aboutPage"
                        component={AboutPage}
                      />
                      <PrivateRoute
                        path="/timesheet/contact"
                        exact
                        key="contactPage"
                        component={ContactPage}
                      />
                      <PrivateRoute
                        path="/timesheet/help"
                        exact
                        key="helpPage"
                        component={HelpPage}
                      />
                      <PrivateRoute
                        path="/timesheet/menu"
                        key="tsMenu"
                        component={TSMenu}
                      />
                      <PrivateRoute
                        path="/timesheet/projects_list"
                        key="projects_list"
                        component={ProjList}
                      />
                      <PrivateRoute
                        path="/timesheet/manage"
                        key="timesheets_list"
                        component={TimesList}
                      />
                      <PrivateRoute
                        path="/timesheet/myCoversheet"
                        key="timesheet_myCoversheet"
                        component={MyCoversheet}
                      />
                      <PrivateRoute
                        path="/timesheet/executiveReview"
                        exact
                        key="timesheet_executiveReview"
                        component={ExecReview}
                        authorizedRoles={['Executive']}
                      />
                      <PrivateRoute
                        path="/timesheet/supervise"
                        exact
                        key="supervise"
                        component={Supervisor}
                      />
                      <PrivateRoute
                        path="/timesheet/metrics"
                        exact
                        key="tsMetrics"
                        component={Metrics}
                      />
                      <PrivateRoute
                        path="/sendEmails"
                        exact
                        key="emailSending"
                        component={SendEmails}
                        authorizedRoles={['Executive', 'FinancialManager']}
                      />
                      <PrivateRoute
                        path="/timesheet/financialManagerDashboard"
                        key="financialManagerDashboard"
                        component={FMDash}
                        authorizedRoles={['FinancialManager']}
                      />
                      <PrivateRoute
                        path="/"
                        exact
                        key="landingFromMistake"
                        component={HomePage}
                      />
                      <Route key="404" component={NotFound} />
                    </Switch>
                  </Suspense>
                </Suspense>
              </ErrorBoundry>
            </ApolloProvider>
          </ScrollToTop>
        </BrowserRouter>
      </IntlProvider>
    </AppProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
unregisterServiceWorker();
