import React, { useReducer, useEffect } from 'react';

const loadState = () => {
  try {
    const serializedState = window.localStorage.getItem('state');
    if (serializedState === null) {
      return initialState;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return initialState;
  }
};

const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    window.localStorage.setItem('state', serializedState);
  } catch (err) {
    // Ignore the errors here
  }
};

const AppStateContext = React.createContext();
const AppDispatchContext = React.createContext();

const initialState = {
  user: {},
  session: {
    expiresAt: '',
    isExpired: true,
    authToken: ''
  },
  failedToFetch: false
};

function emailReducer(state, action) {
  console.log('reducer was called with: ', action);
  switch (action.type) {
    case 'set_from_persisted':
      return action.state;
    case 'clear_profile':
      return initialState;
    case 'set_is_expired':
      return { ...state, session: { ...state.session, isExpired: action.isExpired } };
    case 'set_state_after_login':
      return {
        user: action.user,
        session: {
          expiresAt: action.expiresAt,
          isExpired: false,
          authToken: action.token
        }
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AppProvider({ children }) {
  const [state, dispatch] = useReducer(emailReducer, loadState());

  useEffect(() => {
    console.log('state was updated, should call save');
    console.log('state to be saved is: ', state);
    saveState(state);
  }, [state]);

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

function useAppState() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) throw new Error('useAppState must be within an AppProvider');
  return context;
}

function useAppDispatch() {
  const context = React.useContext(AppDispatchContext);
  if (context === undefined)
    throw new Error('useAppDispatch must be within a AppProvider');
  return context;
}

function appLogin(dispatch, token, user, expiresAt, from, history) {
  dispatch({
    type: 'set_state_after_login',
    token,
    user,
    expiresAt
  });

  console.log('history is ', history);
  // If the from prop is not equal to /, then we need to navigate to that route
  if (from && from !== '/') history.push(from);
  else history.push('/timesheet/menu');
}

async function appLogout(dispatch, history) {
  console.log('app logout was called');
  dispatch({ type: 'clear_profile' });
  history.push('/login');
}

export { AppProvider, useAppState, useAppDispatch, appLogin, appLogout };
