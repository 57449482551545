import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAppDispatch, useAppState } from 'app-context';

// Generate a private route. Whenever it renders, it checks if we are
// Authenticated at the moment. If not, then it calls clearProfile
// to erase the profile we have at the moment
const PrivateRoute = ({ component: Component, authorizedRoles, ...rest }) => {
  const dispatch = useAppDispatch();
  const {
    user: { timesheetRoles },
    session: { isExpired }
  } = useAppState();
  // Only dispatch this if needed
  if (isExpired) dispatch({ type: 'clear_profile' });

  console.log('timesheet roles are; ', timesheetRoles);

  return (
    <Route
      {...rest}
      render={props => {
        if (isExpired)
          return (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          );
        if (
          authorizedRoles &&
          authorizedRoles.some(role => timesheetRoles.indexOf(role) === -1)
        )
          return <Redirect to={'/home'} />;
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
