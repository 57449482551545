import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { persistCache } from 'apollo-cache-persist';
import { setContext } from 'apollo-link-context';
import { get } from 'utils/index';

const uri =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:9000/graphql'
    : 'https://timesheet.psi.edu:5000/graphql';

const httpLink = createHttpLink({
  uri: uri
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from wherever you store it
  let currState = localStorage.getItem('state'); // return the headers to the context so httpLink can read them
  if (currState !== null) currState = JSON.parse(currState);
  const token = get(currState, ['session', 'authToken']);
  const newHeaders = {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
  return newHeaders;
});

// const cache = new InMemoryCache({
//   cacheRedirects: {
//     Query: {
//       allTimesheets: (_, args, { getCacheKey }) =>
//         getCacheKey({ __typename: 'Timesheet', id: args.id })
//     }
//   }
// });

const cache = new InMemoryCache();

// persistCache({
//   cache,
//   storage: window.localStorage,
// });

// Cache persistance was disabled just for development,
// was causing some problems

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  connectToDevTools: true
});
