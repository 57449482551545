import React, { useEffect, useState } from 'react';
import decode from 'jwt-decode';
import ReactDOM from 'react-dom';
import { useAppState, useAppDispatch, appLogout } from 'app-context';
import { apiUri, fetchWithTimeout } from 'utils/index';
import LoadingButton from 'components/LoadingButton';
import useLoadingButtonState from 'hooks/useLoadingButtonState';
const modalRoot = document.getElementById('modal-root');

function millisToMinutesAndSeconds(millis) {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
}

const SessionTimeoutAlertCountdown = ({ history }) => {
  const appState = useAppState();
  const appDispatch = useAppDispatch();
  // Keep a running countdown of how long is left
  const [timeRemaining, setTimeRemaining] = useState(
    new Date(expiresAt - new Date().getTime())
  );
  const [ignoringTimeout, setIgnoringTimeout] = useState(false);
  const [isAboutToExpire, setIsAboutToExpire] = useState(false);
  const [loadingButtonState, loadingButtonDispatch] = useLoadingButtonState();
  const [failedReason, setFailedReason] = useState('');

  useEffect(() => {
    const timeID = setInterval(() => tick(), 1000);
    return () => clearInterval(timeID);
  });

  const expiresAt = appState.session.expiresAt;
  const prevIsExpired = appState.session.isExpired;

  const tick = () => {
    if (!prevIsExpired) {
      const timeRemaining = new Date(expiresAt * 1000 - new Date().getTime());
      const isAboutToExpire =
        timeRemaining.getTime() <= 2 * 60000 && timeRemaining.getTime() > 0;
      const isExpired = timeRemaining.getTime() < 0;

      setTimeRemaining(timeRemaining);
      setIsAboutToExpire(isAboutToExpire);

      if (isExpired) {
        appLogout(appDispatch, history);
        setIgnoringTimeout(false);
        setIsAboutToExpire(false);
      }
    }
  };

  // TODO: take care of the unsuccesfull response
  const getRefreshedToken = async currToken => {
    loadingButtonDispatch('pushing');
    const serverRes = await fetchWithTimeout(`${apiUri}/extendSession`, {
      method: 'POST',
      headers: { authorization: `Bearer ${currToken}` }
    })
      .then(res => res.json())
      .then(res => res)
      .catch(err => {
        let failedReason = '';
        if (err.message === '401') failedReason = 'Invalid Credentials';
        else if (err.message === 'Request timed out') failedReason = 'Timed out';
        else failedReason = 'Login Not Available';
        setFailedReason(failedReason);
        loadingButtonDispatch('failed');
      });

    var decoded = decode(serverRes.token);
    const user = decoded.user;
    user.active
      ? (user.timesheetRoles = ['ActiveEmployee'])
      : (user.timesheetRoles = ['InactiveEmployee']);
    user.timesheetRoles = user.timesheetRoles.concat(user.adminRoles);

    loadingButtonDispatch('succeeded');
    setTimeout(() => {
      appDispatch({
        type: 'set_state_after_login',
        token: serverRes.token,
        user,
        expiresAt: decoded.exp
      });
    }, 500);

    setIgnoringTimeout(false); // Just in case it was true for some reason
  };

  const shouldShowModal = isAboutToExpire && !ignoringTimeout && !prevIsExpired;

  return ReactDOM.createPortal(
    <div
      className={`modal ${shouldShowModal ? 'open' : ''}`}
      onClick={() => setIgnoringTimeout(true)}
    >
      <div className="content-wrapper">
        <p className="modal-header heading-2">Session Expiring</p>
        <div className="modal-content">
          <p className="heading-3">
            {`Your Session will expire in ${millisToMinutesAndSeconds(
              timeRemaining
            )} minutes`}
          </p>
          <p className="heading-4">
            You can choose to either extend your session or let it expire. You will be
            logged out once it expires!
          </p>
        </div>
        <div
          className="grid grid-button-list margin-2-b"
          onClick={e => e.stopPropagation()}
        >
          <button className="btn--outlined" onClick={() => setIgnoringTimeout(true)}>
            Don't Extend
          </button>
          <LoadingButton
            buttonClasses="btn"
            succeeded={loadingButtonState.succeeded}
            isLoading={loadingButtonState.pushing}
            failed={loadingButtonState.failed}
            onClick={() => getRefreshedToken(appState.session.authToken)}
            onLoaderClick={() => loadingButtonDispatch('idle')}
            succeededMessage="Extension Succesful!"
            failedMessage={failedReason}
          >
            Extend Session
          </LoadingButton>
        </div>
      </div>
    </div>,
    modalRoot
  );
};

export default SessionTimeoutAlertCountdown;
